@use 'theme/sass' as *;

.topBarModule {
    > .contentWrapper {
        > .content {
            @include displayFlex(center, flex-start, row);
            gap: 15px;

            @include widthHeight(100%, 100%);

            .form {
                @include widthHeight(100%, min-content);

                .inputFieldContainer {
                    @include displayGrid((16fr 20fr 20fr 20fr 12fr 12fr), flex-start, flex-start);
                    gap: scaleValue(6px, 10px);

                    margin-block-start: $common_inputTopMargin;

                    @include max(1400px) {
                        grid-template-columns: 33fr 33fr 33fr;
                    }
                    @include max(991px) {
                        grid-template-columns: 50fr 50fr;
                    }

                    @include widthHeight(100%, min-content);

                    .inputField {
                        @include widthHeight(100%, min-content);
                    }

                    .selectOption {
                        @include widthHeight(100%, scaleValue(38px, 45px));
                    }
                }
            }
        }
    }
}
