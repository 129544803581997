@use 'theme/sass' as *;

.pastSessionDetailsModule {
    @include widthHeight(100%, 100%);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(100%, 100%);
            @include padding($common_mainContentGap, $common_mainContentGap);

            .loadingContainer {
                height: 29px;

                .loadingBtn {
                    @include padding(2px, 10px);
                }
            }
        }
    }
}
