@use 'theme/sass' as *;

.webinarReportComponent {
    > .contentWrapper {
        > .content {
            position: relative;
            @include widthHeight(100%, 100%);

            .webinarDetails {
                @include displayFlex(flex-start, flex-start, column);
                @include widthHeight(100%, max-content);

                .definitionTable {
                    @include displayFlex(flex-start, flex-start, column);
                    gap: 10px;

                    padding-block-start: 20px;
                    padding-inline: 20px;

                    .head {
                        padding-block-end: 5px;
                    }

                    .text {
                        .highlight {
                            font-weight: var(--fw_semiBold);
                        }
                    }
                }
            }
        }
    }
}

.csvInputModal {
    @include padding(25px, 50px);
}
