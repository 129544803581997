@use 'theme/sass' as *;

$avatarDimension: 55px;

.currentClassCardModule {
    position: relative;

    @include widthHeight(100%, min-content);
    @include padding(15px, 15px);

    > .contentWrapper {
        @include widthHeight(100%, min-content);

        > .content {
            @include displayFlex(center, flex-start, row);
            gap: 15px;

            @include widthHeight(100%, min-content);

            .avatarContainer {
                @include widthHeight($avatarDimension, $avatarDimension);
            }

            .basicDetails {
                @include displayFlex(flex-start, center, column);
                gap: 2px;

                width: calc(100% - $avatarDimension - 10px);

                :is(.name, .email) {
                    display: inline-block;

                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .classTypeContainer {
                position: absolute;
                inset: 5px 5px auto auto;

                .classType {
                    font-weight: var(--fw_medium);
                    color: var(--clr_text_secondary);
                    letter-spacing: 0.5px;

                    @include padding(2px, 7px);
                    border-radius: 3px;

                    &.SAVED {
                        background: var(--clr_bg_info);
                    }
                    &.PUBLIC {
                        background: var(--clr_bg_success);
                    }
                }
            }
        }
    }
}
