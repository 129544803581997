@use 'theme/sass' as *;

.warningModalTemplate {
    position: absolute;
    inset: 0 0 0 0;

    @include displayFlex(center, center, row);
    @include widthHeight(inherit, inherit);

    :global(.MuiBackdrop-root) {
        background: var(--overlay_primary);
    }

    .content {
        @include displayFlex(center, center, column);
        gap: 20px;

        text-align: center;

        @include widthHeight(scaleValue(260px, 450px), max-content);
        @include padding(scaleValue(40px, 50px), scaleValue(20px, 40px));
        border-radius: $common_borderRadius;
        background: var(--clr_bg_primary);

        .headContainer {
            @include displayFlex(center, center, row);
            gap: 15px;

            @include padding(7px, 25px);
            border-radius: 3px;
            border-inline: 3px solid var(--clr_text_error);
            background: var(--clr_bg_error_extraLight);

            .icon {
                font-size: 17px;
                color: var(--clr_text_error);
            }

            .head {
                color: var(--clr_text_error);
            }
        }

        .subHead {
            line-height: scaleValue(18px, 20px);

            .highlight {
                font-weight: var(--fw_semiBold);
            }
        }

        .btnContainer {
            @include displayFlex(center, center, row);
            gap: 10px;

            padding-block-start: 10px;

            .btn {
                @include padding(7px, 25px);
            }
        }
    }
}
