@use 'theme/sass' as *;

.deleteUserContentModule {
    > .contentWrapper {
        > .content {
            @include displayFlex(center, center, column);
            gap: 10px;

            @include widthHeight(max-content, max-content);
            @include padding(scaleValue(40px, 50px), scaleValue(20px, 40px));

            .inputFieldContainer {
                @include displayFlex(center, center, column);
                gap: 12px;

                @include widthHeight(300px, max-content);
                padding-block-start: 20px;

                .inputField {
                    @include widthHeight(100%, max-content);

                    :global(.MuiFormControl-root) {
                        width: 100%;

                        :global(.MuiInputBase-root) {
                            width: 100%;

                            :global(.MuiInputBase-input) {
                                @include widthHeight(100%, $common_inputHeight);
                                padding-inline-end: 55px;
                            }
                        }
                    }

                    :global(.MuiCheckbox-root) {
                        inset: 20% 10px auto auto;
                    }
                }
            }

            .btnContainer {
                @include displayFlex(center, center, row);
                gap: 10px;

                width: 100%;

                .btn {
                    width: 100%;
                    @include padding(7px, 50px);
                }
            }

            .response {
                padding-block-start: 20px;
            }
        }
    }
}
