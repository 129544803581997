@use 'theme/sass' as *;

$tabChangerHeight: 48px;

.tabLayoutTemplate {
    > .contentWrapper {
        > .content {
            @include displayFlex(flex-start, flex-start, column);
            @include widthHeight(100%, 100%);

            .mobileNav {
                @include widthHeight(100%, $mobileTopBar_height);
            }

            .mainContainer {
                @include displayFlex(flex-start, flex-start, column);
                gap: $common_mainContentGap;

                @include widthHeight(100%, 100%);
                padding-inline: $common_mainContentGap;

                &.mobileTopBarGap {
                    @include widthHeight(100%, calc(100% - $mobileTopBar_height));
                }

                &.defaultPadding {
                    padding-block-start: $common_mainContentGap;

                    .details {
                        padding-inline: $common_mainContentGap;
                    }
                }

                .topBarContainer {
                    @include widthHeight(100%, min-content);
                    @include padding($common_mainContentGap, $common_mainContentGap);
                    border-radius: $common_borderRadius;
                    background: var(--clr_bg_primary);
                }

                .details {
                    position: relative;

                    display: grid;
                    grid-template-rows: $tabChangerHeight 1fr;
                    align-items: flex-start;
                    justify-content: stretch;

                    @include widthHeight(100%, 100%);
                    border-radius: $common_borderRadius;
                    background: var(--clr_bg_primary);

                    .backBtnContainer {
                        position: absolute;
                        inset: 4px auto auto auto;
                        z-index: 1;

                        @include displayFlex(center, center, row);
                        @include padding(7px, 7px);
                        cursor: pointer;

                        .icon {
                            font-size: 25px;
                        }

                        &.leftPadding {
                            left: $common_mainContentGap;
                        }
                    }

                    .tabChanger {
                        @include widthHeight(100%, $tabChangerHeight);
                        border-bottom: 1px solid var(--clr_text_neutral_light);

                        &.addBefore {
                            padding-inline-start: 40px;

                            :global(.MuiTabs-flexContainer) {
                                &::before {
                                    content: '';
                                    width: 50px;
                                }
                            }
                        }
                    }

                    .tabContentWrapper {
                        position: relative;

                        @include widthHeight(100%, 100%);
                        overflow: auto;

                        .tabContent {
                            position: absolute;

                            @include displayFlex(flex-start, flex-start, row);
                            gap: $common_mainContentGap;

                            @include widthHeight(100%, 100%);

                            @include max(1199px) {
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        }
    }
}
