@use 'theme/sass' as *;

.jsonViewerTemplate {
    @include widthHeight(100%, 100%);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include widthHeight(100%, 100%);
            padding-block: $common_mainContentGap;

            font-size: 13px;
            transition: none;

            :global(.copy-to-clipboard-container) {
                svg {
                    font-size: 14px !important;
                }
            }
        }
    }
}
