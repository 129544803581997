@use 'theme/sass' as *;

.instantAlertComponent {
    position: absolute;
    inset: 30px 0 0 0;
    z-index: 10;

    width: min-content;
    height: min-content;
    margin-inline: auto;

    > .contentWrapper {
        > .content {
            @include displayFlex(center, center, row);
            gap: 5px;

            width: min-content;
            @include padding(15px, 35px);
            border-radius: 5px;

            &.success {
                background: var(--clr_bg_success_extraLight);

                :is(.icon, .text) {
                    color: var(--clr_bg_success_dark);
                }
            }

            &.error {
                background: var(--clr_bg_error_extraLight);

                :is(.icon, .text) {
                    color: var(--clr_text_error_light);
                }
            }

            .iconContainer {
                .icon {
                    font-size: 25px;
                }
            }

            .text {
                font-weight: var(--fw_medium);
                white-space: nowrap;
            }
        }
    }
}
