@use 'theme/sass' as *;

.dataGridTemplate {
    flex-shrink: 0;

    @include widthHeight(100%, min-content);
    border-radius: $common_borderRadius;

    &.fullHeight {
        @include widthHeight(100%, 100%);

        > .contentWrapper {
            @include widthHeight(100%, 100%);

            > .content {
                @include widthHeight(100%, 100%);

                .mainContainer {
                    @include widthHeight(100%, 100%);
                }
            }
        }
    }

    > .contentWrapper {
        @include widthHeight(100%, min-content);

        > .content {
            @include widthHeight(100%, min-content);

            > .heading {
                color: var(--clr_text_info);

                padding-inline: $common_mainContentGap;
                padding-block: $common_blockPadding;
                border-block-end: $common_headingDividerThickness solid var(--clr_text_neutral_light);
            }

            .mainContainer {
                @include widthHeight(100%, min-content);
            }
        }
    }
}
