@use 'theme/sass' as *;

.uploadContentComponent {
    > .contentWrapper {
        > .content {
            @include widthHeight(100%, 100%);
            @include padding($common_mainContentGap, $common_mainContentGap);
            overflow: auto;

            .form {
                @include displayFlex(flex-start, flex-start, column);
                gap: 15px;

                @include widthHeight(100%, max-content);
                @include padding($common_mainContentGap, $common_mainContentGap);
                border-radius: $common_borderRadius;
                background: var(--clr_bg_primary);

                .inputWrapper {
                    @include displayFlex(flex-start, flex-start, column);
                    @include widthHeight(100%, max-content);

                    > div {
                        @include widthHeight(100%, max-content);
                    }

                    .inputFieldContainer {
                        @include widthHeight(100%, max-content);

                        .fileInputField {
                            @include widthHeight(100%, max-content);
                        }
                    }
                }

                .formFieldsContainer {
                    max-width: calc(100% - 200px);
                }

                .fileUrl {
                    color: var(--clr_text_success);
                    cursor: pointer;
                }

                .error {
                    color: var(--clr_text_error);
                }
            }
        }
    }
}
