@use 'theme/sass' as *;

.viewEditContentDetailsModule {
    @include widthHeight(100%, 100%);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(100%, min-content);
            @include padding($common_mainContentGap, $common_mainContentGap);

            @include max(1199px) {
                padding-block-start: 0;
            }

            .editBtn {
                @include padding(3px, 20px);
            }
        }
    }
}
