@use 'theme/sass' as *;

.mobileTopBarModule {
    > .contentWrapper {
        > .content {
            @include displayFlex(center, space-between, row);

            @include widthHeight(100%, 100%);
            @include padding(10px, 15px);
            background: var(--clr_bg_primary);

            .logoContainer {
                @include widthHeight(auto, scaleValue(30px, 45px));

                .logo {
                    @include widthHeight(100%, 100%);
                }
            }

            .mobileNavIconContainer {
                flex-shrink: 0;
                @include displayFlex(center, center, row);

                @include padding(scaleValue(7px, 10px), scaleValue(7px, 10px));
                border-radius: 50%;
                background: var(--clr_bg_info);

                &:hover {
                    background: var(--clr_bg_info_dark);
                }

                .icon {
                    font-size: scaleValue(20px, 25px);
                    color: var(--clr_text_secondary);
                }
            }
        }
    }
}
