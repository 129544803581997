@use 'theme/sass' as *;

.applyKeyComponent {
    > .contentWrapper {
        > .content {
            @include widthHeight(100%, max-content);

            .applyKeyContent {
                @include displayFlex(flex-start, flex-start, column);
                gap: $common_mainContentGap;

                width: 100%;
                padding-block-end: $common_mainContentGap;
            }
        }
    }
}

.csvInputModal {
    @include padding(25px, 50px);
}
