// ===== Mixins & Functions =====
// Scale Size According to Width
@function scaleValue($min, $max) {
    $viewportRange: 1400px - 350px;
    $contentSizeRange: $max - $min;
    $idealSize: calc($min + (100vw * $contentSizeRange / $viewportRange));
    @return clamp($min, $idealSize, $max);
}

// Width & Height
@mixin widthHeight($width, $height) {
    width: $width;
    height: $height;
}

// Padding
@mixin padding($block, $inline) {
    padding-block: $block;
    padding-inline: $inline;
}

// Margin
@mixin margin($block, $inline) {
    margin-block: $block;
    margin-inline: $inline;
}

// Flexbox
@mixin displayFlex($align, $justify, $dir) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
    flex-direction: $dir;
}

// Grid
@mixin displayGrid($column, $align, $justify) {
    display: grid;
    grid-template-columns: $column;
    align-items: $align;
    justify-content: $justify;
}

// Media Query
@mixin min($minWidth) {
    @media (min-width: $minWidth) {
        @content;
    }
}

@mixin max($maxWidth) {
    @media (max-width: $maxWidth) {
        @content;
    }
}
