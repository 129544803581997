@use 'theme/sass' as *;

$avatarDimension: 55px;

.userCardModule {
    position: relative;

    @include widthHeight(100%, min-content);
    @include padding(23px, 15px);

    > .contentWrapper {
        @include widthHeight(100%, min-content);

        > .content {
            @include displayFlex(center, flex-start, row);
            gap: 15px;

            @include widthHeight(100%, min-content);

            .avatarContainer {
                @include widthHeight($avatarDimension, $avatarDimension);
            }

            .basicDetails {
                @include displayFlex(flex-start, center, column);
                gap: 2px;

                width: calc(100% - $avatarDimension - 10px);

                :is(.name, .email) {
                    display: inline-block;

                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .badgeContainer {
                    @include displayFlex(center, flex-start, row);
                    gap: 20px;

                    @include widthHeight(max-content, min-content);
                    padding-block-start: 5px;

                    .badge {
                        @include displayFlex(center, flex-start, row);
                        gap: 4px;

                        @include widthHeight(100%, max-content);

                        .image {
                            @include widthHeight(18px, 18px);
                        }

                        .text {
                            font-weight: var(--fw_medium);
                            text-align: center;
                        }
                    }
                }
            }

            .accountTypeContainer {
                position: absolute;
                inset: 5px 5px auto auto;

                .accountType {
                    font-weight: var(--fw_medium);
                    color: var(--clr_text_secondary);
                    letter-spacing: 0.5px;

                    @include padding(2px, 7px);
                    border-radius: 3px;

                    &.PREMIUM {
                        color: var(--clr_text_primary);
                        background: #fbe652;
                    }
                    &.PRO {
                        background: var(--clr_bg_info);
                    }
                    &.TRIAL {
                        background: var(--clr_bg_warn);
                    }
                    &.BASIC {
                        background: var(--clr_bg_success);
                    }
                }
            }
        }
    }
}
