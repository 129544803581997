@use 'theme/sass' as *;

.selectOption {
    :global(.MuiFormLabel-root) {
        &:global(.MuiInputLabel-shrink) {
            top: 3px;
        }
    }
}

.hideValue {
    position: relative;

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

    .checkbox {
        position: absolute;
        inset: 25% 3px auto auto;

        .icon {
            font-size: 22px;
        }
    }
}

:global(.MuiInputLabel-root) {
    line-height: unset !important;
}

.fileInput {
    .inputContainer {
        position: relative;

        @include displayFlex(flex-start, center, column);
        gap: 6px;

        margin-block-start: 5px;
        @include widthHeight(100%, max-content);

        .dummyContainer {
            @include displayFlex(center, center, row);
            gap: 10px;

            @include widthHeight(100%, scaleValue(38px, 45px));
            border: 2px solid var(--clr_text_info);
            border-radius: 5px;
            white-space: nowrap;

            &.error {
                border-color: var(--clr_text_error);
            }

            .label {
                width: max-content;
                max-width: calc(100% - 80px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            :is(.fileIcon, .cancelIcon) {
                font-size: 22px;
            }

            .fileIcon {
                color: var(--clr_text_success);
            }

            .cancelIcon {
                color: var(--clr_text_error);
                cursor: pointer;
            }
        }

        .inputField {
            position: absolute;
            inset: 0 0 0 0;

            @include widthHeight(100%, 100%);
            opacity: 0;
            cursor: pointer;

            &.inactive {
                pointer-events: none;
            }
        }
    }
}
