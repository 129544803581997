@use 'theme/sass' as *;

.createKeyComponent {
    > .contentWrapper {
        > .content {
            @include widthHeight(100%, 100%);

            .createKeyContent {
                @include displayFlex(flex-start, flex-start, column);
                gap: $common_mainContentGap;

                width: 100%;
            }
        }
    }
}
