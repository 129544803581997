@use 'theme/sass' as *;

.liveSessionsComponent {
    > .contentWrapper {
        > .content {
            @include widthHeight(100%, 100%);

            .details {
                width: 100%;

                // :global(.MuiToolbar-root) {
                //     :global(.MuiIconButton-root) {
                //         &[aria-label='Reload live classes'] {
                //             display: none;
                //         }
                //     }
                // }

                :global(.MuiTableBody-root) {
                    :global(.MuiTableRow-root) {
                        :global(.MuiTableCell-root) {
                            :global(.MuiButtonBase-root) {
                                padding-block: 10px;
                                padding-inline: 10px;
                                border-radius: 50%;
                            }
                        }
                    }
                }

                :global(.MuiToolbar-root) {
                    :global(.MuiTablePagination-displayedRows) {
                        padding-inline-end: 15px;
                    }

                    span {
                        padding-inline: 4px;
                    }
                }

                .viewRegion {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    margin-block-start: 10px;

                    .text {
                        padding-inline-end: 10px;
                    }
                }
            }
        }
    }
}

:global(.MuiList-root) {
    padding-block: 0 !important;

    :global(.MuiMenuItem-root) {
        padding-block: 5px;
        padding-inline: 12px;
        border-right: 2px solid var(--clr_bg_neutral_light);
    }
}
