@use './theme/sass/root' as *;
@use './theme/sass' as *;

/* Apply to All Components */
* {
    margin: 0;
    padding: 0;
    @include padding(0, 0);
    @include margin(0, 0);
    box-sizing: border-box;

    transition: 0.1s all ease-out;
    font-family: var(--font_primary) !important;
}

/* Body */
:is(html, body, #root) {
    @include widthHeight(100%, 100%);
    scroll-behavior: smooth;

    /* Keeps The Body Centered in Small Screens */
    @media (max-width: 350px) {
        width: 350px;
        margin: auto;
    }
}

:is(button, input, select, option) {
    border: none;
    outline: none;
}

// Number Input Field Arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    appearance: textfield;
}

:is(h1, h2, h3, h4, h5, h6, p, a, th, td) {
    color: var(--clr_text_primary);
}

a {
    text-decoration: none;
}

/* Selection */
::selection {
    color: var(--clr_text_secondary);
    background: var(--clr_bg_info);
    -webkit-text-fill-color: var(--clr_text_secondary);
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border: 3px solid var(--clr_white);
    border-radius: 100px;
    background: var(--clr_text_info_extraDark_faded);
}

// ::-webkit-scrollbar {
//     display: none;
// }
