@use 'theme/sass' as *;

.generateAvatar {
    @include displayFlex(center, center, row);

    @include widthHeight(100%, 100%);
    aspect-ratio: 1;
    border-radius: 50%;

    .image {
        @include widthHeight(100%, 100%);
        border-radius: 50%;
    }

    .text {
        color: var(--clr_text_secondary);
    }
}
