@use 'theme/sass' as *;

.webinarReportDetailsModule {
    @include widthHeight(100%, 100%);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include widthHeight(100%, 100%);
            @include padding($common_mainContentGap, $common_mainContentGap);

            .loadingBtn {
                padding-inline: 7px;
            }
        }
    }
}
