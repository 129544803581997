@use 'theme/sass' as *;

.textboxContainer {
    flex-shrink: 0;
    @include displayFlex(center, flex-start, column);
    gap: 2px;

    @include widthHeight(100%, calc(100% - 20px));

    .textbox {
        flex-shrink: 0;
        @include widthHeight(100%, calc(100% - 50px));

        :global(.ql-container) {
            border-color: var(--clr_bg_neutral_light);

            :global(.ql-editor) {
                font-size: 16px;
                height: 100%;
            }
        }
    }

    .toolbar {
        @include displayFlex(center, center, row);

        @include widthHeight(max-content, 50px);
        padding-inline: 40px;
        border-color: var(--clr_bg_neutral_light) !important;

        .tools {
            @include displayFlex(center, center, row);
            gap: 7px;

            margin: 0;

            &::after {
                display: none;
            }

            .item {
                @include displayFlex(center, center, row);

                font-size: 15px;

                @include widthHeight(max-content, 33px);
                padding-block: 7px;
                padding-inline: 15px;
                border-radius: 3px;
                background: var(--clr_bg_neutral_extraLight);

                &.size {
                    :global(.ql-picker-label) {
                        padding: 0;

                        &::before {
                            line-height: normal;
                        }

                        > svg {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
