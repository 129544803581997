@use 'theme/sass' as *;

$itemHeight: 42px;
$userDetailsHeight: 70px;
$navPadding: 15px;

.navigation {
    @include widthHeight(100%, 100%);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            @include widthHeight(100%, 100%);

            .profileContainer {
                @include displayFlex(center, flex-start, row);
                gap: 10px;

                @include widthHeight(100%, $userDetailsHeight);
                padding-block: $navPadding 5px;
                padding-inline: $navPadding;

                .avatarContainer {
                    @include widthHeight(48px, 48px);
                }

                .detailsContainer {
                    @include displayFlex(flex-start, center, column);
                    @include widthHeight(100%, 100%);

                    :is(.displayName, .accessLevel, .email) {
                        display: inline-block;

                        width: 100%;
                        max-width: 165px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .accessLevel {
                        font-weight: var(--fw_semiBold);
                        color: var(--clr_text_success);
                    }
                }
            }

            .navContainer {
                @include displayFlex(flex-start, flex-start, column);
                @include widthHeight(100%, calc(100% - $userDetailsHeight));

                .navGroup {
                    @include displayFlex(flex-start, flex-start, column);
                    @include widthHeight(100%, min-content);

                    .mainLevelItemContainer {
                        @include widthHeight(100%, min-content);

                        .mainLevelItem {
                            @include displayFlex(center, flex-start, row);
                            gap: 15px;

                            @include widthHeight(100%, min-content);
                            @include padding(12px, 25px);

                            &:hover {
                                background: var(--clr_bg_info_extremeLight);
                            }

                            .iconContainer {
                                .icon {
                                    font-size: 23px;
                                    color: var(--clr_text_info_extraDark_faded);

                                    transition: none;
                                }
                            }

                            &.expandableItem {
                                :global(:is(.expandCloseIcon, .expandOpenIcon)) {
                                    color: var(--clr_text_neutral);
                                    margin-inline-start: auto;
                                }
                            }

                            &.active {
                                &:hover {
                                    background: transparent;
                                }

                                .iconContainer {
                                    .icon {
                                        color: var(--clr_text_info);
                                    }
                                }

                                .title {
                                    font-weight: var(--fw_medium);
                                    color: var(--clr_text_info);
                                }

                                &.expandableItem {
                                    :global(:is(.expandCloseIcon, .expandOpenIcon)) {
                                        color: var(--clr_text_info_dark);
                                    }
                                }
                            }
                        }

                        .subMenuContainer {
                            flex-shrink: 0;
                            @include widthHeight(100%, min-content);

                            .subLevelItem {
                                position: relative;

                                justify-content: flex-start;

                                @include widthHeight(100%, min-content);
                                padding-block: 12px;
                                padding-inline: 64px 5px;

                                &:hover {
                                    background: var(--clr_bg_info_extremeLight);
                                }

                                .title {
                                    letter-spacing: 0.25px;
                                }

                                &.active {
                                    background: var(--clr_bg_info_extremeLight);

                                    &::before {
                                        position: absolute;
                                        inset: 0 0 0 auto;
                                        content: '';

                                        width: 2px;
                                        background: var(--clr_text_info);
                                    }

                                    .title {
                                        color: var(--clr_text_info);
                                        font-weight: var(--fw_medium);
                                    }
                                }
                            }
                        }
                    }

                    &:nth-of-type(1) {
                        padding-block: 10px 25px;
                        overflow: auto;
                    }

                    &:nth-last-of-type(1) {
                        margin-block-start: auto;
                        padding-block-start: 25px;

                        .mainLevelItemContainer {
                            .mainLevelItem {
                                &:is(:hover, .active) {
                                    :is(svg, .title) {
                                        color: var(--clr_text_secondary);
                                    }
                                }
                            }

                            &:nth-last-of-type(1) {
                                .mainLevelItem {
                                    background: var(--clr_bg_error_extraLight);

                                    svg {
                                        color: var(--clr_bg_error_light);
                                    }

                                    &:hover {
                                        background: var(--clr_bg_error_light);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
