@use 'theme/sass' as *;

.getAllAffiliateComponent {
    @include widthHeight(100%, 100%);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include widthHeight(100%, 100%);

            .moduleContainer {
                @include displayFlex(flex-start, flex-start, column);
                gap: 10px;

                @include widthHeight(100%, 100%);
                background: var(--clr_bg_primary);
            }
        }
    }
}
