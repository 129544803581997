@use 'theme/sass' as *;

:is(.keyDetailsModule, .jsonDetailsModule) {
    @include widthHeight(100%, 100%);

    @include max(1199px) {
        @include widthHeight(100%, min-content);
    }

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        @include max(1199px) {
            @include widthHeight(100%, min-content);
        }

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(100%, min-content);
            padding-block: $common_mainContentGap;

            @include max(1199px) {
                padding-block-start: 0;
            }

            .tableBtnContainer {
                @include displayFlex(center, center, row);
                gap: 10px;

                height: 100%;

                .tableBtn {
                    height: 100%;
                    white-space: nowrap;

                    padding-inline: 15px;
                }
            }
        }
    }
}
