@use 'theme/sass' as *;

.aiAccessDetailsModule {
    @include widthHeight(100%, 100%);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include displayFlex(center, center, column);
            gap: $common_mainContentGap;

            @include widthHeight(100%, min-content);
            @include padding($common_mainContentGap, $common_mainContentGap);
        }
    }
}
