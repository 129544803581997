@use 'theme/sass' as *;

.sideBarComponent {
    > .contentWrapper {
        > .content {
            @include widthHeight(100%, 100%);
            border-inline-end: 2px solid var(--clr_text_neutral_extraLight);
        }
    }
}
