@use 'theme/sass' as *;

.createContentComponent {
    > .contentWrapper {
        > .content {
            @include widthHeight(100%, max-content);

            &.fullScreen {
                height: 100%;
            }

            .createKeyContent {
                @include displayFlex(flex-start, flex-start, column);
                gap: $common_mainContentGap;

                width: 100%;
                padding-block-end: $common_mainContentGap;
            }
        }
    }
}
