@use 'theme/sass' as *;

.dataGridTemplate {
    flex-shrink: 0;
    @include widthHeight(100%, min-content);

    &.fullHeight {
        @include widthHeight(100%, 100%);

        > .contentWrapper {
            @include widthHeight(100%, 100%);

            > .content {
                @include widthHeight(100%, 100%);
            }
        }
    }

    &:global(.checkUserDetails) {
        > .contentWrapper {
            > .content {
                > .detailsContainer {
                    .row {
                        .details {
                            .valueContainer {
                                .fieldValue {
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    > .contentWrapper {
        @include widthHeight(100%, min-content);

        > .content {
            @include widthHeight(100%, min-content);

            > .headingContainer {
                @include displayFlex(center, space-between, row);

                padding-inline: $common_mainContentGap;
                padding-block: $common_blockPaddingSmall;
                border-block-end: $common_headingDividerThickness solid var(--clr_text_neutral_light);

                .heading {
                    color: var(--clr_text_info);
                }
            }

            > .detailsContainer {
                @include displayFlex(flex-start, flex-start, column);

                @include widthHeight(100%, min-content);
                padding-block: $common_blockPadding;

                .row {
                    display: grid;
                    grid-auto-flow: column;
                    grid-auto-columns: 1fr;
                    gap: 15px;

                    @include widthHeight(100%, min-content);
                    padding-inline: $common_mainContentGap;
                    border-block-end: $common_dataDividerThickness solid var(--clr_text_neutral_light);

                    &:nth-last-of-type(1) {
                        border-block-end: none;
                    }

                    .details {
                        @include displayFlex(flex-start, stretch, column);
                        gap: 4px;

                        @include widthHeight(100%, 100%);
                        padding-block: 8px;
                        overflow: hidden;

                        .fieldName {
                            color: var(--clr_text_neutral_dark);

                            &.clickable {
                                cursor: pointer;
                            }
                        }

                        .valueContainer {
                            @include displayFlex(center, flex-start, row);
                            gap: 10px;

                            @include widthHeight(100%, 100%);

                            .fieldValue {
                                word-break: break-all;

                                :global(.divider) {
                                    display: block;
                                    padding-block: 2px;
                                }

                                :global(.bold) {
                                    font-weight: var(--fw_semiBold);
                                }

                                :global(.clickable) {
                                    cursor: pointer;

                                    &:hover {
                                        color: var(--clr_text_info);
                                    }
                                }

                                &.singleLine {
                                    display: inline-block;

                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }

                                &.highlight {
                                    cursor: pointer;

                                    &:hover {
                                        color: var(--clr_text_info);
                                    }
                                }
                            }

                            .btn {
                                @include padding(3px, 8px);
                            }
                        }
                    }
                }
            }
        }
    }
}
