@use 'theme/sass' as *;

.simpleSearchBoxTemplate {
    > .contentWrapper {
        > .content {
            @include displayFlex(flex-start, flex-start, row);
            gap: 10px;

            @include widthHeight(100%, min-content);
            margin-block-start: $common_inputTopMargin;

            .inputContainer {
                .inputField {
                    @include widthHeight(scaleValue(0px, 500px), min-content);
                    @include max(991px) {
                        width: scaleValue(65px, 440px);
                    }
                    @include max(767px) {
                        width: scaleValue(110px, 440px);
                    }

                    :global(.MuiInputBase-root) {
                        width: 100%;

                        :global(.MuiInputBase-input) {
                            @include widthHeight(100%, scaleValue(38px, 45px));
                        }
                    }
                }

                .error {
                    color: var(--clr_text_error);
                    padding-block-start: 8px;
                }
            }

            .submitBtn {
                @include widthHeight(min-content, scaleValue(38px, 45px));
                padding-inline: scaleValue(0px, 50px);
                white-space: nowrap;
            }
        }
    }
}
