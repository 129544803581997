@use 'theme/sass' as *;

:is(.createKeyDetailsModule) {
    @include widthHeight(100%, min-content);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        @include max(1199px) {
            @include widthHeight(100%, min-content);
        }

        > .content {
            @include widthHeight(100%, min-content);

            .form {
                @include displayGrid((50fr 25fr 25fr), flex-start, flex-start);
                gap: 10px;

                margin-block-start: $common_inputTopMargin;
            }
        }
    }
}
