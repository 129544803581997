@use './helper' as *;

// ===== Constant Values =====
$common_borderRadius: 5px;
$common_dataDividerThickness: 0.5px;
$common_headingDividerThickness: 1px;

$sidebar_width: 270px;
$mobileTopBar_height: 60px;
$common_mainContentGap: scaleValue(8px, 15px);

$common_inputHeight: 50px;
$common_inputTopMargin: 5px;

$common_blockPadding: scaleValue(8px, 10px);
$common_blockPaddingSmall: scaleValue(3px, 5px);

$common_shadow: 0px 0px 50px var(--clr_black_fade_alpha_20);

// ===== Font Size =====
// Heading
@mixin fontHead1() {
    font-size: scaleValue(23px, 26px) !important;
    font-weight: var(--fw_bold);
}

// Body
@mixin fontBodyLarge() {
    font-size: scaleValue(14px, 15px) !important;
}

@mixin fontBody() {
    font-size: scaleValue(13px, 14px) !important;
}
