@use 'theme/sass' as *;

:is(
        .personalDetailsModule,
        .subscriptionDetailsModule,
        .savedClassesModule,
        .userAnalyticsModule,
        .usageLogsModule,
        .debugLogsModule,
        .currentClassDetailsModule,
        .jsonDetailsModule
    ) {
    @include widthHeight(100%, 100%);

    &::-webkit-scrollbar {
        display: none;
    }

    @include max(1199px) {
        flex-shrink: 0;

        &:not(.removeMinContentHeight, .userAnalyticsModule) {
            height: min-content;

            > .contentWrapper {
                height: min-content;
            }
        }
    }

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(100%, min-content);
            padding-block: $common_mainContentGap;

            @include max(1199px) {
                padding-block-start: 0;
            }

            &.analyticsContent {
                height: 100%;
                padding-inline: $common_mainContentGap;

                .iframe {
                    width: 100%;
                    height: inherit;
                    border-width: 0;
                }
            }

            .analyticsSmallContainer .analyticsContent {
                height: 250px;
            }

            &:is(.usageLogsContent, .debugLogsContent) {
                @include widthHeight(100%, 100%);
                padding-block: $common_mainContentGap;

                :is(.usageLogsDatePicker, .debugLogsDatePicker) {
                    @include displayFlex(center, center, row);
                    gap: 15px;

                    :is(.refreshDataBtn, .exportCsvDataBtn) {
                        @include padding(3px, 15px);
                    }

                    :is(.usageLogsText, .debugLogsText) {
                        flex-shrink: 0;
                        @include displayFlex(center, center, row);

                        :is(.usageLogsDateField, .debugLogsDateField) {
                            line-height: 23px;
                            width: 100px;
                        }
                    }

                    :is(.usageLogsDateArrowBtn, .debugLogsDateArrowBtn) {
                        @include padding(8px, 8px);

                        .arrowIcon {
                            font-size: 16px;
                        }
                    }
                }
            }

            &.debugLogsContent {
                .headingContainer {
                    @include displayFlex(center, space-between, row);
                    gap: 10px;

                    @include widthHeight(100%, 45px);
                    @include padding(22px, 16px);
                    border: 2px solid var(--clr_text_neutral_extraLight);
                    border-radius: 3px;

                    .head {
                        color: var(--clr_text_info);

                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .logText {
                    line-height: 25px;
                    white-space: pre-wrap;
                    word-break: break-all;

                    @include widthHeight(100%, 100%);
                    overflow: auto;
                }
            }

            .timeStampBtnContainer {
                @include displayFlex(flex-start, flex-start, row);
                gap: 10px;
                flex-wrap: wrap;

                .timeStampBtn {
                    @include padding(5px, 18px);

                    &.active {
                        background: var(--clr_bg_success);
                    }
                }
            }

            :is(.noLiveClassText) {
                padding-inline-start: 7px;
            }
        }
    }
}

.currentActivityJsonViewModalContent {
    width: 90vw;
    min-width: 300px;
    max-width: scaleValue(200px, 700px);
    height: 85vh;
    max-height: scaleValue(400px, 550px);
    @include padding(10px, 20px);
    overflow: auto;

    @include max(767px) {
        @include padding(15px, 10px);
    }
}
