@use 'theme/sass' as *;

.pastSessionDetailsModule {
    @include widthHeight(100%, 100%);

    &:not(.removeMinContentHeight) {
        height: min-content;

        > .contentWrapper {
            height: min-content;
        }
    }

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(100%, 100%);
            @include padding($common_mainContentGap, $common_mainContentGap);

            .pastSessionsPageNumber {
                @include displayFlex(center, center, row);
                gap: 10px;

                @include widthHeight(min-content, 100%);

                :is(.rowsPerPageText, .pageNumberText) {
                    flex-shrink: 0;
                    @include displayFlex(center, center, row);

                    :is(.pageNumberInputField, .rowsPerPageInputField) {
                        line-height: 23px;
                        width: 55px;

                        :global(.MuiInputBase-input) {
                            padding-inline: 5px;
                        }
                    }
                }

                .pageNumberArrowBtn {
                    flex-shrink: 0;
                    @include padding(8px, 8px);

                    .arrowIcon {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
