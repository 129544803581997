@use 'theme/sass' as *;

.commonModalTemplate {
    position: absolute;
    inset: 0 0 0 0;

    @include displayFlex(center, center, row);
    @include widthHeight(inherit, inherit);

    :global(.MuiBackdrop-root) {
        background: var(--overlay_primary) !important;
    }

    > .contentWrapper {
        > .content {
            position: relative;

            @include displayFlex(center, center, row);

            border-radius: $common_borderRadius !important;
            background: var(--clr_bg_primary) !important;
            background-color: var(--clr_bg_primary) !important;

            .body {
                @include widthHeight(max-content, max-content);
                max-width: 60vw !important;
                max-height: 80vh !important;
                overflow: auto;
            }

            .closeBtn {
                position: absolute;
                inset: -15px -15px auto auto;
                z-index: 10;

                color: var(--clr_white) !important;

                @include widthHeight(30px, 30px);
                min-width: 0 !important;
                min-height: 0 !important;
                padding: 0 !important;
                border-radius: 100%;
                background: var(--clr_bg_info) !important;
                background-color: var(--clr_bg_info) !important;
            }
        }
    }
}
