@use 'theme/sass' as *;

.latestUsersDetailsModule {
    @include widthHeight(100%, 100%);

    &:not(.removeMinContentHeight) {
        height: min-content;

        > .contentWrapper {
            height: min-content;
        }
    }

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include widthHeight(100%, 100%);
            @include padding($common_mainContentGap, $common_mainContentGap);
            overflow: auto;

            .latestUsersPageNumber {
                @include displayFlex(center, center, row);
                gap: 10px;

                @include widthHeight(min-content, 100%);

                :is(.rowsPerPageText, .pageNumberText) {
                    flex-shrink: 0;
                    @include displayFlex(center, center, row);

                    :is(.pageNumberInputField, .rowsPerPageInputField) {
                        line-height: 23px;
                        width: 55px;

                        :global(.MuiInputBase-input) {
                            padding-inline: 5px;
                        }
                    }
                }

                .pageNumberArrowBtn {
                    flex-shrink: 0;
                    @include padding(8px, 8px);

                    .arrowIcon {
                        font-size: 16px;
                    }
                }
            }

            .loadingText {
                padding-block-start: 20px;
                padding-inline-start: 5px;
            }

            .errorText {
                color: var(--clr_text_error);
                padding-block-start: 20px;
                padding-inline-start: 5px;
            }
        }
    }
}

.usageLogsJsonViewModalContent {
    width: 90vw;
    min-width: 300px;
    max-width: scaleValue(200px, 700px);
    height: 85vh;
    max-height: scaleValue(400px, 550px);
    @include padding(10px, 20px);
    overflow: auto;

    @include max(767px) {
        @include padding(15px, 10px);
    }
}

.tableOptions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px 20px;

    padding-block-end: 15px;
    padding-inline: 7px;
}
