@use '../sass' as *;

// Headings
.head1 {
    font-size: scaleValue(23px, 26px) !important;
    font-weight: var(--fw_bold);
}

.head2 {
    font-size: scaleValue(21px, 24px) !important;
    font-weight: var(--fw_bold);
}

.head3 {
    font-size: scaleValue(19px, 22px) !important;
    font-weight: var(--fw_semiBold);
}

.head4 {
    font-size: scaleValue(17px, 20px) !important;
    font-weight: var(--fw_semiBold);
}

.head5 {
    font-size: scaleValue(15px, 18px) !important;
    font-weight: var(--fw_semiBold);
}

.head6 {
    font-size: scaleValue(13px, 16px) !important;
    font-weight: var(--fw_semiBold);
}

// Sub Headings
.subHead {
    font-size: scaleValue(17px, 20px) !important;
    font-weight: var(--fw_medium);
}

// Body
.bodyXl {
    font-size: scaleValue(15px, 16px) !important;
}

.bodyLarge {
    font-size: scaleValue(14px, 15px) !important;
}

.body {
    font-size: scaleValue(13px, 14px) !important;
}

.bodySmall {
    font-size: scaleValue(12px, 13px) !important;
}

.bodyXs {
    font-size: scaleValue(11px, 12px) !important;
}

.bodyUltraSmall {
    font-size: scaleValue(8px, 10px) !important;
}
