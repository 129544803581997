@use 'theme/sass' as *;

.activateTrialModule {
    @include widthHeight(100%, min-content);

    > .contentWrapper {
        @include widthHeight(100%, min-content);

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: 5px;

            @include widthHeight(100%, min-content);

            :is(.successMessage, .errorMessage) {
                margin-inline: auto;
                padding-block-end: 5px;
            }

            .successMessage {
                color: var(--clr_text_success);
            }

            .errorMessage {
                color: var(--clr_text_error);
            }
        }
    }
}
