@use 'theme/sass' as *;

.affiliateSummaryModule {
    flex-shrink: 0;
    @include widthHeight(min-content, 100%);

    @include max(1199px) {
        @include widthHeight(100%, min-content);
    }

    > .contentWrapper {
        @include widthHeight(min-content, 100%);

        @include max(1199px) {
            @include widthHeight(100%, min-content);
        }

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(350px, min-content);
            padding-block: $common_mainContentGap;

            @include max(1199px) {
                width: 100%;
                padding-block-end: 0;
            }
        }
    }
}
