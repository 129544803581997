@use 'theme/sass' as *;

.dataTableTemplate {
    @include widthHeight(100%, auto);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            @include widthHeight(100%, 100%);

            > .headingContainer {
                position: relative;

                @include displayFlex(center, space-between, row);
                gap: 10px;

                @include widthHeight(100%, 45px);
                @include padding(8px, 16px);
                border-block-end: $common_headingDividerThickness solid var(--clr_text_neutral_light);

                .headingText {
                    .backBtnContainer {
                        position: absolute;
                        inset: 2px auto auto 10px;
                        z-index: 1;

                        @include displayFlex(center, center, row);
                        @include padding(7px, 7px);
                        cursor: pointer;

                        .icon {
                            font-size: 25px;
                        }

                        &.leftPadding {
                            left: $common_mainContentGap;
                        }
                    }

                    .head {
                        color: var(--clr_text_info);

                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &.leftPadding {
                            padding-inline-start: 45px;
                        }
                    }
                }

                .collapseBtn {
                    @include widthHeight(32px, 32px);

                    .icon {
                        font-size: 18px;

                        &.collapsed {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            > .tableContainer {
                @include widthHeight(100%, 100%);

                .table {
                    position: relative;

                    :is(.tableHead, .tableBody) .tableRow {
                        border-radius: 3px;

                        .tableData {
                            cursor: pointer;
                        }
                    }

                    .tableHead {
                        position: sticky;
                        inset: 0 0 auto 0;

                        background: var(--clr_white);
                        box-shadow: $common_shadow;

                        .tableRow {
                            .tableData {
                                font-weight: var(--fw_semiBold);
                                @include padding($common_blockPadding, $common_blockPadding);

                                .icon {
                                    font-size: 19px;
                                    margin-block-end: -6px;

                                    &.descOrder {
                                        transform: rotate(180deg);
                                    }
                                }

                                &.clickable {
                                    color: var(--clr_text_info);
                                }
                            }
                        }
                    }

                    .tableBody {
                        .tableRow {
                            .tableData {
                                @include padding(5px, $common_blockPadding);
                                pointer-events: none;

                                &.noWrap {
                                    white-space: nowrap;
                                }

                                @include min(992px) {
                                    word-break: break-all;
                                }

                                &.clickable {
                                    pointer-events: all;

                                    &:hover {
                                        color: var(--clr_text_info);
                                    }
                                }

                                .imageContainer {
                                    @include widthHeight(30px, 30px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
