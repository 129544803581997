@use 'theme/sass' as *;

.userSummaryModule {
    flex-shrink: 0;
    @include widthHeight(min-content, 100%);

    &::-webkit-scrollbar {
        display: none;
    }

    @include max(1199px) {
        @include widthHeight(100%, min-content);
    }

    > .contentWrapper {
        @include widthHeight(min-content, 100%);

        @include max(1199px) {
            @include widthHeight(100%, min-content);
        }

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(350px, min-content);
            padding-block: $common_mainContentGap;

            @include max(1199px) {
                width: 100%;
                padding-block-end: 0;
            }

            .options {
                width: 100%;
                height: $common_inputHeight;

                :global(.MuiSelect-nativeInput) {
                    font-size: 15px;

                    @include widthHeight(100%, 100%);
                    padding-inline: 10px;
                    opacity: 0.75;
                }
            }
        }
    }
}

.userActionList {
    padding-block: 0 !important;
    padding-inline: 0 !important;

    .userActionListBtn {
        @include widthHeight(100%, 40px);
        border-radius: 0;
    }
}
