:root {
    // ========== Colour ==========
    --clr_white: #ffffff;
    --clr_white_alpha_70: hsla(0, 100%, 100%, 0.7);
    --clr_white_alpha_25: hsla(0, 100%, 100%, 0.25);

    --clr_black: #000000;
    --clr_black_fade: #222222;
    --clr_black_fade_alpha_20: hsl(0, 0%, 13.3%, 0.2);

    // Text
    --clr_text_primary: #222222;
    --clr_text_secondary: #ffffff;

    --clr_text_neutral: #bfbfbf;
    --clr_text_neutral_light: #e6e6e6;
    --clr_text_neutral_extraLight: #f1f1f1;
    --clr_text_neutral_extremeLight: #f5f5f7;
    --clr_text_neutral_dark: #808080;

    --clr_text_info: #6378ff;
    --clr_text_info_light: #8192ff;
    --clr_text_info_extraLight: #c8d2ff;
    --clr_text_info_extremeLight: #ebedff;
    --clr_text_info_dark: #4658cf;
    --clr_text_info_extraDark: #1b2141;
    --clr_text_info_extraDark_faded: #474c61;

    --clr_text_success: #5db625;
    --clr_text_success_light: #bdd900;
    --clr_text_success_dark: #2e9145;

    --clr_text_warn: #ff9800;
    --clr_text_warn_light: #fdbb3e;
    --clr_text_warn_dark: #e58923;

    --clr_text_error: #df1d1d;
    --clr_text_error_light: #ff6262;
    --clr_text_error_extraLight: #ffdddd;
    --clr_text_error_dark: #bd231b;

    --clr_text_brown: #98241e;

    // Background
    --clr_bg_primary: #ffffff;
    --clr_bg_primary_dark: #f4f7ff;

    --clr_bg_secondary: #222222;

    --clr_bg_neutral: #bfbfbf;
    --clr_bg_neutral_light: #e6e6e6;
    --clr_bg_neutral_extraLight: #f1f1f1;
    --clr_bg_neutral_extremeLight: #f5f5f7;
    --clr_bg_neutral_dark: #808080;

    --clr_bg_info: #6378ff;
    --clr_bg_info_light: #8192ff;
    --clr_bg_info_extraLight: #c8d2ff;
    --clr_bg_info_extremeLight: hsl(234, 100%, 97.1%);
    --clr_bg_info_dark: #4658cf;
    --clr_bg_info_extraDark: #1b2141;
    --clr_bg_info_extraDark_faded: #4d505e;

    --clr_bg_success: #5db625;
    --clr_bg_success_light: #bdd900;
    --clr_bg_success_extraLight: #dff5d3;
    --clr_bg_success_dark: #2e9145;

    --clr_bg_warn: #ff9800;
    --clr_bg_warn_light: #fdbb3e;
    --clr_bg_warn_dark: #e58923;

    --clr_bg_error: #df1d1d;
    --clr_bg_error_light: #ff6262;
    --clr_bg_error_extraLight: #ffdddd;
    --clr_bg_error_dark: #bd231b;

    // Overlay
    --overlay_primary: hsla(0, 0%, 0%, 0.7);

    // ========== Font Family ==========
    --font_primary: 'Public Sans', sans-serif;

    // ========== Font Weight ==========
    --fw_light: 300;
    --fw_regular: 400;
    --fw_medium: 500;
    --fw_semiBold: 600;
    --fw_bold: 700;
    --fw_extraBold: 800;
    --fw_black: 900;
}
