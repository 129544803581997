@use 'theme/sass' as *;

.multipleSearchBoxTemplate {
    > .contentWrapper {
        > .content {
            @include displayFlex(flex-start, flex-start, row);
            gap: 10px;

            @include widthHeight(100%, min-content);

            .inputContainer {
                @include widthHeight(100%, min-content);

                .formFieldsContainer {
                    @include widthHeight(100%, min-content);

                    :global(.MuiInputBase-root) {
                        width: 100%;

                        :global(.MuiInputBase-input) {
                            @include widthHeight(100%, scaleValue(38px, 45px));
                        }
                    }

                    :global(.MuiButtonBase-root) {
                        white-space: nowrap;
                        @include widthHeight(100%, scaleValue(38px, 45px));
                    }
                }

                :is(.error, .success) {
                    font-weight: var(--fw_medium);
                    line-height: 20px;

                    padding-block-start: 8px;
                }

                .error {
                    color: var(--clr_text_error);
                }

                .success {
                    color: var(--clr_text_success);
                }
            }

            .submitBtn {
                @include widthHeight(min-content, scaleValue(38px, 45px));
                margin-block-start: $common_inputTopMargin;
                padding-inline: scaleValue(10px, 50px);
                white-space: nowrap;
            }
        }
    }
}
