@use 'theme/sass' as *;

.editCctModalContentModule {
    > .contentWrapper {
        > .content {
            @include displayFlex(center, center, column);
            gap: 30px;

            @include widthHeight(max-content, max-content);
            @include padding(scaleValue(40px, 50px), scaleValue(20px, 40px));

            .inputFieldContainer {
                @include displayFlex(center, center, row);
                gap: scaleValue(8px, 12px);

                @include widthHeight(max-content, max-content);

                .fieldWrapper {
                    @include displayFlex(center, center, row);
                    gap: scaleValue(8px, 12px);
                    flex-wrap: wrap;

                    @include widthHeight(scaleValue(260px, 600px), max-content);

                    .inputField {
                        flex-basis: scaleValue(100px, 220px);
                        flex-grow: 1;

                        @include widthHeight(100%, max-content);

                        :global(.MuiInputBase-root) {
                            width: 100%;

                            :global(.MuiInputBase-input) {
                                @include widthHeight(100%, $common_inputHeight);
                            }
                        }
                    }
                }

                .bioEditContainer {
                    @include displayFlex(flex-start, center, column);
                    gap: 10px;

                    @include widthHeight(450px, 475px);
                }
            }

            .btnContainer {
                @include displayFlex(center, center, row);
                gap: 10px;

                .btn {
                    @include padding(7px, 50px);
                }
            }
        }
    }
}
