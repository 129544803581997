@use 'theme/sass' as *;

:is(.comingSoonComponent, .customErrorComponent) {
    > .contentWrapper {
        @include displayFlex(center, center, column);

        > .content {
            @include displayFlex(center, center, column);
            gap: 10px;

            @include widthHeight(min-content, min-content);
            @include padding(50px, 75px);
            border-radius: 10px;
            background: var(--clr_bg_primary);

            .iconContainer {
                @include displayFlex(center, center, row);

                .icon {
                    font-size: 35px;
                    color: var(--clr_text_error_light);
                }
            }

            .text {
                font-weight: var(--fw_medium);
                white-space: nowrap;
            }
        }
    }
}
