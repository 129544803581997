@use 'theme/sass' as *;

.homePage {
    @include displayFlex(flex-start, flex-start, row);

    > .contentWrapper {
        &.sidebar {
            @include widthHeight($sidebar_width, 100%);

            &.mobileView {
                width: 0;
            }
        }

        &.main {
            @include widthHeight(calc(100% - $sidebar_width), 100%);
            background: var(--clr_bg_neutral_extremeLight);

            &.fullWidth {
                @include widthHeight(100%, 100%);
            }
        }

        > .content {
            @include widthHeight(100%, 100%);
        }
    }
}
