@use 'theme/sass' as *;

.createKeySummaryModule {
    @include widthHeight(100%, 100%);

    @include max(1199px) {
        @include widthHeight(100%, min-content);
    }

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        @include max(1199px) {
            @include widthHeight(100%, min-content);
        }

        > .content {
            @include displayGrid(repeat(3, 1fr), stretch, flex-start);
            gap: $common_mainContentGap;

            @include widthHeight(100%, min-content);
            padding-block-end: $common_mainContentGap;

            @include max(1399px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include max(1199px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .headBtn {
                @include padding(3px, 10px);
            }
        }
    }
}
