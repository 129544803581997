@use 'theme/sass' as *;

.latestUsersDetailsModule {
    @include widthHeight(100%, 100%);

    &:not(.removeMinContentHeight) {
        height: min-content;

        > .contentWrapper {
            height: min-content;
        }
    }

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(100%, 100%);
            @include padding($common_mainContentGap, $common_mainContentGap);

            .headBtn {
                padding-block: 2px;
                padding-inline: 10px;
            }
        }
    }
}
