@use 'theme/sass' as *;

.loadingComponent {
    > .contentWrapper {
        @include displayFlex(center, center, column);

        > .content {
            @include displayFlex(center, center, column);
            gap: 30px;

            @include widthHeight(min-content, min-content);

            .loaderContainer {
                @include displayFlex(center, center, row);
                gap: 32px;

                .loader {
                    --animationDuration: 3s;
                    position: relative;
                    @include widthHeight(44px, 44px);

                    &:before {
                        content: '';
                        position: absolute;
                        inset: 37px auto auto 19px;

                        @include widthHeight(6px, 6px);
                        border-radius: 50%;
                        background: var(--clr_text_info);
                        transform: translate(-18px, -18px);
                        animation: dotRect var(--animationDuration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
                    }

                    svg {
                        @include widthHeight(100%, 100%);

                        rect,
                        polygon,
                        circle {
                            fill: none;
                            stroke: var(--clr_text_info_extraDark);
                            stroke-width: 10px;
                            stroke-linejoin: round;
                            stroke-linecap: round;
                        }

                        polygon {
                            stroke-dasharray: 145 (221 - 145) 145 (221 - 145);
                            stroke-dashoffset: 0;
                            animation: pathTriangle var(--animationDuration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
                                infinite;
                        }

                        rect {
                            stroke-dasharray: calc(calc(256 / 4) * 3) calc(256 / 4) calc(calc(256 / 4) * 3)
                                calc(256 / 4);
                            stroke-dashoffset: 0;
                            animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
                        }

                        circle {
                            stroke-dasharray: calc(calc(200 / 4) * 3) calc(200 / 4) calc(calc(200 / 4) * 3)
                                calc(200 / 4);
                            stroke-dashoffset: 75;
                            animation: pathCircle var(--animationDuration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
                                infinite;
                        }
                    }

                    &.triangle {
                        width: 48px;

                        &:before {
                            left: 21px;
                            transform: translate(-10px, -18px);
                            animation: dotTriangle var(--animationDuration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
                                infinite;
                        }
                    }
                }

                @keyframes pathTriangle {
                    33% {
                        stroke-dashoffset: 74;
                    }
                    66% {
                        stroke-dashoffset: 147;
                    }
                    100% {
                        stroke-dashoffset: 221;
                    }
                }

                @keyframes dotTriangle {
                    33% {
                        transform: translate(0, 0);
                    }
                    66% {
                        transform: translate(10px, -18px);
                    }
                    100% {
                        transform: translate(-10px, -18px);
                    }
                }

                @keyframes pathRect {
                    25% {
                        stroke-dashoffset: 64;
                    }
                    50% {
                        stroke-dashoffset: 128;
                    }
                    75% {
                        stroke-dashoffset: 192;
                    }
                    100% {
                        stroke-dashoffset: 256;
                    }
                }

                @keyframes dotRect {
                    25% {
                        transform: translate(0, 0);
                    }
                    50% {
                        transform: translate(18px, -18px);
                    }
                    75% {
                        transform: translate(0, -36px);
                    }
                    100% {
                        transform: translate(-18px, -18px);
                    }
                }

                @keyframes pathCircle {
                    25% {
                        stroke-dashoffset: 125;
                    }
                    50% {
                        stroke-dashoffset: 175;
                    }
                    75% {
                        stroke-dashoffset: 225;
                    }
                    100% {
                        stroke-dashoffset: 275;
                    }
                }
            }

            .text {
                font-weight: var(--fw_medium);
                color: var(--clr_text_success);
            }
        }
    }
}
