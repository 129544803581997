@use 'theme/sass' as *;

$common_mainContentGap: $common_mainContentGap;

.verifyApplyKeySummaryModule {
    @include widthHeight(100%, min-content);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        @include max(1199px) {
            @include widthHeight(100%, min-content);
        }

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(100%, min-content);
            padding-block-start: $common_mainContentGap;

            @include max(1199px) {
                width: 100%;
            }

            .dataGroup {
                @include displayFlex(flex-start, flex-start, column);
                gap: $common_mainContentGap;

                width: 100%;

                .tableBtn {
                    height: 100%;
                    white-space: nowrap;

                    padding-inline: 15px;
                }
            }
        }
    }
}
