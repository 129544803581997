@use 'theme/sass' as *;

.cctComponent {
    > .contentWrapper {
        > .content {
            position: relative;
            @include widthHeight(100%, 100%);

            .cctDetails {
                @include displayFlex(flex-start, flex-start, column);
                @include widthHeight(100%, max-content);
            }
        }
    }
}

.csvInputModal {
    @include padding(25px, 50px);
}

.singleCctDetails {
    @include displayGrid(repeat(2, 1fr), flex-start, flex-start);
    gap: 15px 50px;

    max-width: 750px;
    height: max-content;
    @include padding(35px, 35px);
}
