@use 'theme/sass' as *;

.latestUsersComponent {
    > .contentWrapper {
        > .content {
            position: relative;
            @include widthHeight(100%, 100%);
        }
    }
}
