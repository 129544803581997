@use '../sass' as *;

.pageWrapper {
    position: relative;

    @include widthHeight(100%, 100%);
    background: var(--clr_bg_primary);
    overflow: hidden;

    > .contentWrapper {
        @include widthHeight(100%, 100%);
    }
}

.componentWrapper {
    @include widthHeight(100%, 100%);

    > .contentWrapper {
        @include widthHeight(100%, 100%);
    }
}

.moduleWrapper {
    overflow: auto;

    > .contentWrapper {
        @include displayFlex(flex-start, flex-start, column);
    }
}
