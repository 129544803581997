@use 'theme/sass' as *;

.keyDetailsModule {
    @include widthHeight(100%, 100%);

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        > .content {
            @include widthHeight(100%, 100%);
            @include padding($common_mainContentGap, $common_mainContentGap);
        }
    }
}
