@use 'theme/sass' as *;

.topBarModule {
    > .contentWrapper {
        > .content {
            @include displayFlex(flex-start, flex-start, row);
            gap: 10px;

            @include widthHeight(100%, 100%);

            .form {
                @include widthHeight(100%, min-content);

                .inputFieldContainer {
                    @include displayGrid((75fr 25fr), flex-start, flex-start);
                    gap: scaleValue(6px, 10px);

                    @include widthHeight(100%, min-content);
                    margin-block-start: $common_inputTopMargin;

                    .inputField {
                        @include widthHeight(100%, min-content);
                    }
                }
            }

            .uploadDataBtn {
                white-space: nowrap;

                @include widthHeight(max-content, scaleValue(38px, 45px));
                margin-block-start: 4px;
                padding-inline: 30px;
            }
        }
    }
}
