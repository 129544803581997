@use 'theme/sass' as *;

.verifyApplyKeyModalContent {
    width: 90vw;
    min-width: 300px;
    max-width: scaleValue(200px, 700px);
    height: 85vh;
    max-height: scaleValue(400px, 550px);
    @include padding(20px, 20px);
    overflow: auto;

    @include max(767px) {
        @include padding(15px, 10px);
    }
}
