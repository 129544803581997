@use 'theme/sass' as *;

.currentClassSummaryModule {
    flex-shrink: 0;
    @include widthHeight(min-content, 100%);

    @include max(1199px) {
        @include widthHeight(100%, min-content);
    }

    > .contentWrapper {
        @include widthHeight(min-content, 100%);

        @include max(1199px) {
            @include widthHeight(100%, min-content);
        }

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(350px, min-content);
            padding-block: $common_mainContentGap;

            @include max(1199px) {
                width: 100%;
                padding-block-end: 0;
            }

            .jsonViewBtn {
                width: 100%;
                @include padding(8px, 15px);
            }
        }
    }
}

.currentClassJsonViewModalContent {
    width: 90vw;
    min-width: 300px;
    max-width: scaleValue(200px, 700px);
    height: 85vh;
    max-height: scaleValue(400px, 550px);
    @include padding(10px, 20px);
    overflow: auto;

    @include max(767px) {
        @include padding(15px, 10px);
    }
}
