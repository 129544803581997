@use 'theme/sass' as *;

.loginComponent {
    > .contentWrapper {
        overflow: auto;

        > .content {
            @include displayFlex(center, center, row);

            @include widthHeight(100%, 100%);
            @include padding(20px, 20px);

            @media (max-height: 450px) {
                height: auto;
            }

            > .detailsContainer {
                @include displayFlex(center, center, column);
                gap: 20px;

                text-align: center;

                @include widthHeight(min-content, min-content);
                @include padding(scaleValue(5px, 35px), scaleValue(15px, 50px));
                border-radius: $common_borderRadius;
                border: 3px solid var(--clr_text_neutral_extraLight);

                .logoContainer {
                    @include widthHeight(scaleValue(50px, 75px), scaleValue(50px, 75px));

                    .logo {
                        @include widthHeight(100%, 100%);
                    }
                }

                .head {
                    color: var(--clr_text_info);
                }

                .form {
                    @include displayFlex(center, center, column);
                    gap: 30px;

                    :is(.inputField, .submitBtn) {
                        width: scaleValue(220px, 300px);
                    }

                    .inputContainer {
                        @include displayFlex(center, center, column);
                        gap: 10px;

                        @include widthHeight(100%, 100%);

                        :global(.MuiInputBase-input) {
                            padding-block: 5px;
                        }
                    }

                    .error {
                        color: var(--clr_text_error);
                        text-align: left;

                        margin-inline-end: auto;
                        // Nullify extra gap between Error Message and Submit Button
                        margin-block: -15px;
                    }

                    .submitBtn {
                        height: scaleValue(40px, 45px);
                        transition: 0.25s all ease-out;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }
}
