@use 'theme/sass' as *;

.topBarModule {
    > .contentWrapper {
        > .content {
            @include displayFlex(center, flex-start, row);
            gap: 15px;

            @include widthHeight(100%, 100%);

            .form {
                @include widthHeight(100%, min-content);
            }
        }
    }
}
