@use 'theme/sass' as *;

.editModalContentModule {
    > .contentWrapper {
        > .content {
            @include displayFlex(center, center, column);
            gap: 30px;

            @include widthHeight(scaleValue(260px, 550px), max-content);
            @include padding(scaleValue(40px, 50px), scaleValue(20px, 40px));

            .inputFieldContainer {
                @include displayFlex(center, center, row);
                gap: scaleValue(8px, 12px);
                flex-wrap: wrap;

                .inputField {
                    flex-basis: scaleValue(100px, 225px);
                    flex-grow: 1;
                    @include widthHeight(100%, min-content);

                    :global(.MuiInputBase-root) {
                        @include widthHeight(100%, $common_inputHeight);
                    }
                }

                .error {
                    color: var(--clr_text_error);
                }
            }

            .btnContainer {
                @include displayFlex(center, center, row);
                gap: 10px;

                .btn {
                    @include padding(7px, 50px);
                }
            }
        }
    }
}
