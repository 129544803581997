@use 'theme/sass' as *;

:is(.createKeyDetailsModule) {
    @include widthHeight(100%, 100%);

    @include max(1199px) {
        @include widthHeight(100%, min-content);
    }

    > .contentWrapper {
        @include widthHeight(100%, 100%);

        @include max(1199px) {
            @include widthHeight(100%, min-content);
        }

        > .content {
            @include displayFlex(flex-start, flex-start, column);
            gap: $common_mainContentGap;

            @include widthHeight(100%, min-content);

            .formContainer {
                position: relative;

                @include displayFlex(flex-start, flex-start, column);
                gap: 10px;

                @include padding($common_mainContentGap, $common_mainContentGap);

                .form {
                    @include displayFlex(stretch, center, row);
                    gap: 10px;
                    flex-wrap: wrap;

                    @include widthHeight(100%, 100%);

                    > * {
                        flex-shrink: 0;
                        flex-grow: 1;
                        flex-basis: clamp(175px, 18%, 100%);

                        height: $common_inputHeight;
                    }

                    .advanceBtn {
                        position: absolute;
                        inset: 5px 15px auto auto;

                        @include widthHeight(30px, 30px);
                        min-height: 0;
                        border-radius: 50%;

                        .icon {
                            @include fontHead1();
                        }
                    }
                }

                .createBtn {
                    @include widthHeight(100%, $common_inputHeight);
                }

                .error {
                    color: var(--clr_text_error);
                }

                .success {
                    font-weight: var(--fw_medium);
                    color: var(--clr_text_success);
                }
            }
        }
    }
}
