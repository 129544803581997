@use 'theme/sass' as *;

.topBarLayoutTemplate {
    > .contentWrapper {
        > .content {
            @include displayFlex(flex-start, flex-start, column);
            @include widthHeight(100%, 100%);

            .mobileNav {
                @include widthHeight(100%, $mobileTopBar_height);
            }

            .mainContainer {
                @include displayFlex(flex-start, flex-start, column);
                gap: $common_mainContentGap;

                @include widthHeight(100%, 100%);
                padding-block-start: $common_mainContentGap;
                padding-inline: $common_mainContentGap;

                &.mobileTopBarGap {
                    @include widthHeight(100%, calc(100% - $mobileTopBar_height));
                }

                .topBarContainer {
                    @include widthHeight(100%, min-content);
                    @include padding($common_mainContentGap, $common_mainContentGap);
                    border-radius: $common_borderRadius;
                    background: var(--clr_bg_primary);
                }

                .bodyContainer {
                    @include displayFlex(flex-start, flex-start, row);
                    gap: $common_mainContentGap;

                    @include widthHeight(100%, 100%);
                    border-radius: $common_borderRadius;
                    background: var(--clr_bg_primary);
                    overflow: auto;

                    @include max(1199px) {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
