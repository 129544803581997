@use '../sass' as *;

// ===== Select Option Padding =====
.customSelect {
    height: inherit;
    line-height: 0 !important;

    padding-inline-end: 32px;

    :global(.MuiSelect-select) {
        @include displayFlex(flex-start, center, column);

        @include fontBodyLarge();
        color: var(--clr_text_primary);

        width: 100%;
        height: inherit !important;
        min-height: 0 !important;
        padding-block: 0;
        padding-right: 0 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    :global(.MuiOutlinedInput-notchedOutline) {
        top: -4px;
        border-width: 2px;
        border-color: var(--clr_text_neutral_light);
    }

    &:hover {
        :global(.MuiOutlinedInput-notchedOutline) {
            border-color: var(--clr_text_neutral_dark) !important;
        }
    }

    &:global(.Mui-focused) {
        :global(.MuiOutlinedInput-notchedOutline) {
            border-color: var(--clr_text_info) !important;
        }
    }
}
