@use 'theme/sass' as *;

.topBarLayoutTemplate {
    > .contentWrapper {
        > .content {
            @include displayFlex(flex-start, flex-start, column);
            @include widthHeight(100%, 100%);

            .mobileNav {
                @include widthHeight(100%, min-content);
            }

            .mainContainer {
                @include displayFlex(flex-start, flex-start, column);
                gap: $common_mainContentGap;

                @include widthHeight(100%, 100%);
                padding-block-start: $common_mainContentGap;
                padding-inline: $common_mainContentGap;

                .bodyContainer {
                    @include displayFlex(flex-start, flex-start, row);
                    gap: $common_mainContentGap;

                    @include widthHeight(100%, 100%);
                    padding-block-start: $common_mainContentGap;
                    padding-inline: $common_mainContentGap;
                    border-radius: $common_borderRadius;
                    background: var(--clr_bg_primary);
                    overflow: auto;

                    @include max(1199px) {
                        flex-direction: column;
                    }

                    &.noPadding {
                        padding-block-start: 0;
                        padding-inline: 0;
                    }
                }
            }
        }
    }
}
