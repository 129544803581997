@use 'theme/sass' as *;

.userComponent {
    > .contentWrapper {
        > .content {
            @include widthHeight(100%, 100%);
        }
    }
}

.notificationModal {
    scale: 1.3;

    .deleteResponse {
        padding-block: 20px;
        padding-inline: 30px;
    }
}

.modalNotification {
    padding-block: 15px;
    padding-inline: 20px;
}
